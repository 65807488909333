<script setup lang="ts">
import { ref, watch } from 'vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

const props = withDefaults(
  defineProps<{
    size?: 'lg' | 'md' | 'xl'
    open: boolean
    uploading?: boolean
    imageUrl: string | null
    maxArea?: number // e.g. 350 * 350
    aspectRatio?: number // e.g. 12 / 12
    minAspectRatio?: number // e.g. 12 / 12
    // The maximum width and height of the image to which the image will be resized
    maxSize?: { height: number, width: number }
  }>(),
  {
    size: 'lg',
    uploading: false,
    maxArea: undefined,
    aspectRatio: undefined,
    minAspectRatio: undefined,
    maxSize: undefined,
  },
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'resize', resizeEvent: Event): void
  (event: 'crop', canvas: HTMLCanvasElement): void
}>()

defineOptions({
  inheritAttrs: false,
})

const croppedCanvas = ref<HTMLCanvasElement | null>(null)
const cropperRef = ref()

const { t } = useI18n({
  useScope: 'local',
})

const resize = ({ canvas }: { canvas: HTMLCanvasElement }) => {
  if (props.maxSize) {
    const tempCanvas = document.createElement('canvas')
    const context = tempCanvas.getContext('2d')

    if (context) {
      // Resize to max width and height
      tempCanvas.width = props.maxSize.width
      tempCanvas.height = props.maxSize.height
      context.drawImage(canvas, 0, 0, props.maxSize.width, props.maxSize.height)
      croppedCanvas.value = tempCanvas
    }
  }
  else {
    // No resizing needed
    croppedCanvas.value = canvas
  }
}

const cropImage = () => {
  if (!croppedCanvas.value) return
  emit('crop', croppedCanvas.value)
}
</script>

<template>
  <TairoModal
    :open="open"
    :size="size"
    @close="emit('close')"
  >
    <template #header>
      
      <div class="flex w-full items-center justify-between p-4">
        <h3 class="font-heading text-muted-900 text-lg font-medium leading-6 dark:text-white">
          {{ t('crop') }}
        </h3>
        <BaseButtonClose @click="emit('close')" />
      </div>
    </template>

    <Cropper
      v-bind="$attrs"
      ref="cropperRef"
      class="cropper"
      :canvas="{ maxArea: maxArea }"
      :stencil-props="{
        aspectRatio: aspectRatio,
        minAspectRatio: minAspectRatio,
      }"
      :transitions="true"
      :src="imageUrl"
      @change="resize"
    />

    <template #footer>
      
      <div class="p-4">
        <div class="flex items-center justify-end gap-4">
          <BaseButton
            :disabled="uploading"
            :loading="uploading"
            color="primary"
            flavor="solid"
            class="order-last"
            @click="cropImage"
          >
            {{ $t('save') }}
          </BaseButton>
        </div>
      </div>
    </template>
  </TairoModal>
</template>

<style scoped>
.cropper {
  @apply h-[600px] w-[600px];
}
</style>

<i18n lang="json">
{
  "en": {
    "crop": "Crop Photo"
  },
  "nl": {
    "crop": "Foto bijsnijden"
  }
}
</i18n>
